import React, { FC } from 'react';

import WorkPageTemplate from '../../atomic/templates/WorkPageTemplate';

const pageData = {
  header: {
    title: 'Footmarks',
    useCase: 'BRANDING, WEB DESIGN, WEB DEVELOPMENT, VIDEO PRODUCTION',
    description: `Footmarks is a technology company headquartered in Bellevue Washington. It operates the nation’s largest retail beacon network and supports large enterprise clients and partners with some of the largest companies in the world. I designed and developed their new website and graphic assets. The biggest challenge with Footmarks is they have an amazing offering but no visuals to show the value of their technology. I designed all the graphics from scratch to really illustrate how each individual client can leverage their beacon technology. This engagement was very fulfilling as I was able to completely revamp their corporate identity.`,
    link: 'footmarks.com',
  },
  caseId: 'footmarks',
  contents: [
    'image0.jpg',
    'image1.jpg',
    'image2.jpg',
    'image3.jpg',
    {
      jwplayer: {
        videoSrc: 'https://content.jwplatform.com/videos/VLB00G87-giz7EkeH.mp4',
        imageSrc: 'https://content.jwplatform.com/thumbs/VLB00G87-1280.jpg',
      },
    },
    'image4.jpg',
    'image5.jpg',
    'image6.jpg',
    'image7.jpg',
    'image8.jpg',
    'image9.jpg',
  ],
};

export default (() => <WorkPageTemplate data={pageData} />) as FC;
